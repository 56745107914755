<template>
  <div class="wrapper">
    <div>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast
            :key="info.message"
            :show="true"
            :header="info.header"
            :color="info.color"
          >
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CRow>
        <CCol sm="12">
          <CCard>
            <CCardHeader>
              <strong> {{ obj.name }} </strong> Permission
            </CCardHeader>
            <CCardBody>
              <CForm>
                <!-- <CInput
                  label="Code"
                  horizontal
                  v-model="$v.obj.code.$model"
                  :isValid="checkIfValid('code')"
                  invalidFeedback="This is a required field and must be at least 2 characters"
                />
                <CInput
                  label="Name"
                  horizontal
                  v-model="$v.obj.name.$model"
                  :isValid="checkIfValid('name')"
                  invalidFeedback="This is a required field and must be at least 2 characters"
                /> -->
                <CDataTable
                  :items="computedItems"
                  :fields="fields"
                  hover
                  striped
                >
                  <template #cell_canView="{ item }">
                    <td class="py-2">
                      <CSwitch
                        color="primary"
                        :checked.sync="item.canView"
                        label-on="YES"
                        label-off="NO"
                      >
                      </CSwitch>
                    </td>
                  </template>

                  <!-- <template #cell_canAdd="{ item }">
                    <td class="py-2">
                      <CSwitch
                        color="primary"
                        :checked.sync="item.canAdd"
                        label-on="YES"
                        label-off="NO"
                      >
                      </CSwitch>
                    </td>
                  </template>
                  <template #cell_canUpdate="{ item }">
                    <td class="py-2">
                      <CSwitch
                        class="mr-1"
                        color="primary"
                        :checked.sync="item.canUpdate"
                        label-on="YES"
                        label-off="NO"
                      >
                      </CSwitch>
                    </td>
                  </template>
                  <template #cell_canDelete="{ item }">
                    <td class="py-2">
                      <CSwitch
                        class="mr-1"
                        color="primary"
                        :checked.sync="item.canUDelete"
                        label-on="YES"
                        label-off="NO"
                      >
                      </CSwitch>
                    </td>
                  </template> -->
                  <!-- <template #details="{ item }">
                    <CCardBody>
                      <p class="text-muted">Sub Module</p>
                      <CDataTable
                        :items="item.subModules"
                        :fields="fields"
                        hover
                        striped
                      ></CDataTable>
                    </CCardBody>
                  </template> -->
                </CDataTable>
              </CForm>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary" @click="submit"
                ><CIcon name="cil-check-circle" /> Submit</CButton
              >

              <!-- <CButton class="ml-1" color="success"> Validate </CButton>
              <CButton class="ml-1" color="danger"> Reset </CButton> -->
              <CButton class="ml-1" color="secondary" @click="cancel">
                Cancel
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
import RoleApi from "../../lib/roleApi";
import PermissionApi from "../../lib/permissionApi";

const items = [];
const fields = [
  { key: "name", _style: "min-width:200px;" },
  { key: "cell_canView", label: "View" },
  // { key: "cell_canAdd", label: "Add" },
  // { key: "cell_canUpdate", label: "Edit" },
  // { key: "cell_canDelete", label: "Delete" },
  // {
  //   key: "show_details",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];

export default {
  name: "ServiceProvider",
  data: () => {
    return {
      infoList: [],
      api: new RoleApi(),
      permissionApi: new PermissionApi(),
      obj: {},
      loading: false,
      submitted: false,

      items: [],
      fields,
    };
  },
  mounted() {
    var self = this;
    self.resetObj();
  },
  computed: {
    formString() {
      return JSON.stringify(this.obj, null, 4);
    },
    computedItems() {
      return this.items.map((item) => {
        return {
          ...item,
          name: this.getModuleName(item),
        };
      });
    },
  },
  methods: {
    getModuleName(item) {
      return item.module.name;
    },
    onSubmit() {
      var self = this;

      console.log("before", self.obj.roleModulePermissions);
      self.obj.roleModulePermissions = self.computedItems;
      console.log("after", self.obj.roleModulePermissions);

      if (!self.obj.id) {
        this.api
          .create(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/RoleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.obj)
          .then((response) => {
            self.$router.push({ path: "/admins/RoleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.obj = {};
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    resetObj() {
      console.log("resetObj");
      var self = this;
      if (self.$route.params.id) {
        this.api
          .get(self.$route.params.id)
          .then((response) => {
            self.obj = response.result;
            console.log(self.obj);
            self.items = self.obj.roleModulePermissions;
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        self.obj = self.getEmptyObj();
      }
    },

    reset() {
      // this.obj = this.getEmptyObj();
      this.resetObj();
      this.submitted = false;
      this.$v.$reset();
    },
    getEmptyObj() {
      return {
        code: "",
        name: "",
      };
    },
    submit() {
      this.onSubmit();
      this.submitted = true;
    },
    cancel() {
      this.$router.push({ path: "/admins/RoleList" });
    },
  },
};
</script>
